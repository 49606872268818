<template>
  <div class="centered">
    <h1 class="title-section" id="h01">Mon Profil</h1>
    <div class="separate">
      <div class="texte">
        Salut ! je m'appelle Romain et j'aime innover. J'ai commencé en faisant
        des "mods" sur le jeu Minecraft et de fils en aiguilles j'en suis venu à
        créer des intelligences artificielles.
        <br />
        <br />
        J'aimerais m'installer au Canada, plus précisément à Montréal. C'est
        pourquoi, après 3 années d'apprentissage en développement, spécialité
        réalité virtuelle, je suis à l'écoute de nouvelles opportunités pour
        démarrer ma carrière dans le pays du sirop d'érable.
        <br />
        <br />
        Je suis à la recherche dans le domaine de la programmation, orienté
        3D/Jeu-Vidéo, mais j'ai aussi de l'intérêt pour la vision par ordinateur.
      </div>
      <div class="photo">
        <v-img
          cover
          :src="require('../assets/romain.webp')"
          transition="scale-transition"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "APropos",

  data: () => ({
    //
  }),
};
</script>

<style>
h1#h01::before {
  content: "01. ";
  color: var(--green);
}

.separate {
  display: block;
  grid-template-columns: 3fr 2fr;
  gap: 50px;
}

.photo {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

@media (min-width: 960px) {
  .separate {
    display: grid;
  }

  .photo {
    margin: 0;
    width: 300px;
  }
}
</style>