<template>
  <div class="centered">
    <h1 class="title-section" id="h02">Mon Parcours</h1>
    <v-expansion-panels dark>
      <v-expansion-panel v-for="(item, i) in data" :key="i">
        <v-expansion-panel-header> {{ title[i] }} </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-tabs center-active show-arrows dark v-model="tabs">
            <v-tab dark v-for="(donnee, j) in item" :key="j">{{
              donnee.societe
            }}</v-tab>
          </v-tabs>
          <v-tabs-items dark v-model="tabs">
            <v-tab-item v-for="(donnee, j) in item" :key="j">
              <div style="padding-top: 15px">
                {{ donnee.intitule }} <span class="italic">{{ donnee.lieu }}</span>
              </div>
              <div v-if="donnee.a != ''" class="vert">
                De {{ donnee.de }} à {{ donnee.a }}
              </div>
              <div v-else class="vert">Depuis {{ donnee.de }}</div>
              <div v-html="donnee.descriptif"></div>
              <v-chip outlined color="var(--green)" class="ma-2" v-for="(chip, k) in donnee.chips" :key="k">{{chip}}</v-chip>
            </v-tab-item>
          </v-tabs-items>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "Parcours",

  data: () => ({
    tabs: null,
    title: ["Scolaire", "Professionnel"],
    data: [
      [
        {
          intitule: "BAC STI2D",
          societe: "Au Lycée Chevrollier",
          lieu: "Angers, France",
          de: "septembre 2015",
          a: "août 2018",
          descriptif:
            "<br>FR/QC - BAC/DEC<br> Sciences et Technologie de l’Industrie du Développement Durable",
          chips: ["Arduino", "Ionic", "HTML", "CSS", "JavaScript"]
        },
        {
          intitule: "DUT GEII",
          societe: "Á l'Université d'Angers",
          lieu: "Angers, France",
          de: "septembre 2018",
          a: "août 2020",
          descriptif:
            "<br>FR/QC - DUT/Baccalauréat<br> Génie Électrique et Informatique Industrielle",
            chips: ["C/C++", "PHP", "Python", "SQL"]
        },
        {
          intitule: "Semestre à l'étranger",
          societe: "Á l'UQAC",
          lieu: "Chicoutimi, Canada",
          de: "janvier 2020",
          a: "juin 2020",
          descriptif: "<br>Programme court de premier cycle en informatique",
          chips: ["C/C++", "Suite Adobe", "Unreal Engine 4", "Structure de données"]
        },
        {
          intitule: "Ingénieur d'un numérique utile",
          societe: "Á l'ESIEA",
          lieu: "Laval, France",
          de: "septembre 2020",
          a: "août 2023",
          descriptif: "",
          chips: ["C/C++", "C#", "VueJS", "Vuetify", "Unity", "CUDA", "Linux", "Bash/Shell", "PyTorch", "Vision par ordinateur"]
        },
      ],
      [
        {
          intitule: "Développeur web",
          societe: "AMA49",
          lieu: "Angers, France",
          de: "juillet 2018",
          a: "août 2018",
          descriptif: "<br>- Réaliser un site web complet.<br>- Prendre en compte les exigeances clientes.",
          chips: ["HTML", "CSS", "JS", "WordPress", "VueJS", "Strapi", "Développement web"]
        },
        {
          intitule: "Associé des stocks",
          societe: "Banana Republic",
          lieu: "Quebec, Canada",
          de: "juillet 2019",
          a: "août 2019",
          descriptif: "<br>- Préparer et réceptionner des livraisons.<br>- S’assurer que la surface de vente soit bien approvisionnée en tout temps.",
          chips: null
        },
        {
          intitule: "Alternant au sein du bureau d'étude",
          societe: "sedApta-Osys",
          lieu: "Changé, France",
          de: "septembre 2020",
          a: "août 2022",
          descriptif: "<br>- Réaliser l'analyse et le débogage de dysfonctionnements de produits logiciels.<br>- Écrire et développer les spécifications détaillées de nouvelles fonctionnalités.",
          chips: ["Java", "C#", ".NET", "ASP.NET", "Angular", "SQL", "Développement logiciel"]
        },
        {
          intitule: "Alternant en tant qu'ingénieur",
          societe: "QBranch",
          lieu: "Entrammes, France",
          de: "septembre 2022",
          a: "",
          descriptif: "<br>- Réaliser l'analyse et le débogage de dysfonctionnements de produits logiciels.<br>- Écrire et développer les spécifications détaillées de nouvelles fonctionnalités.<br>- Faire de le veille technologique sur l'évolution technique de logiciels",
          chips: ["C/C++", "Python", "C#", "Unity", "Unreal Engine 4", "CUDA", "Développement embarqués", "Développement logiciel", "Vision par ordinateur", "Traitement d'images"]
        },
      ],
    ],
  }),
};
</script>

<style>
h1#h02::before {
  content: "02. ";
  color: var(--green);
}

.italic {
  font-style: italic;
  color: gray;
}
</style>